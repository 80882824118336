//@ts-ignore
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { WEB_COMPONENTS_NAMES } from '@/shared/app';
import { Card, CardAttributes } from '../carousel/model';
import { Badge } from '@/shared/api/arena-data';
import { ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]/types';
import { getValidBadgesBySlug } from '@/shared/utils/utils';
import { TGameData } from '@/root/app/[locale]/games/[slug]/model';

export type TCarouselRecentlyPlayed = {
    __component: WEB_COMPONENTS_NAMES.RecentlyPlayed;
    id: number;
    title: string;
    cardsType: 'small' | 'medium';
    placeholderText: string;
    analyticId: string;
};

export type TOverriddenSlideAttributes = {
    tagName: string;
    attributes: CardAttributes;
    handleClick?: (AITracks?: any) => void;
};

export const overrideSlideAttributes = (
    c: TGameData,
    arenaData: any,
    isMobile: boolean,
    AITracks: any,
    badgesList: Badge[],
    gameFeedData: ShortGameModelFromFeed[],
    size: 'small' | 'medium',
    carouselId?: string | null
): TOverriddenSlideAttributes | null => {
    let attributesOverride = arenaData?.layout?.styleOverride?.standardCard || {};
    let tagName;
    //For Analytics - card click event
    let handleClick;
    // attributes mapping
    const attributes: CardAttributes = {
        onMobile: isMobile,
        size: size,
        action_bar: 'false', // Acceptance criteria: Full background
        thumbnail_mode: 'false',
        title: c?.meta?.name || c?.name || '',
        background_color: 'transparent',
        background_image_desktop: c?.meta?.thumbs?.graphic_288x192 || '',
        background_image_mobile: c?.meta?.thumbs?.graphic_288x192 || '',
        badge: getValidBadgesBySlug(gameFeedData, c?.slug || '', badgesList ?? []),
        link_label: '',
        game_category: c?.meta?.categories?.[0] || '',
        game_alias: c?.meta?.alias || '',
        game_name: c?.name || '',
        game_ext_id: c?.id || 0,
        game_description: c?.meta?.description || '',
        game_slug: c?.slug || '',
        description: c?.meta?.description || '',
        shortDescription: c?.meta?.details || '',
        override_color_title: attributesOverride?.title || '',
        override_hover_block_toggled: attributesOverride?.hoverBlock?.type,
        override_hover_block_color_bcg: attributesOverride?.hoverBlock?.background || '',
        override_hover_block_color_text: attributesOverride?.hoverBlock?.color || '',
        override_hover_block_button_text: attributesOverride?.hoverBlock?.buttonText || 'Play',
        override_hover_block_button_text_color: attributesOverride?.hoverBlock?.buttonTextColor || '#000',
        override_hover_block_button_background_color: attributesOverride?.hoverBlock?.buttonBackground || '#FFF',
        override_hover_block_button_background_color_end: attributesOverride?.hoverBlock?.buttonBackground_end || '#FFF',
        override_hover_block_button_border_color: attributesOverride?.hoverBlock?.buttonBorderColor || '#000',
    };

    tagName = 'ark-ui-block-card-standard';

    handleClick = (): void =>
        AITracks?.genericTrack?.({
            eventName: 'gameCard',
            isNonInteraction: false,
            eventAction: AnalyticsEventAction.CLICK,
            customDimensions: {
                cardType: 'StandardCard',
                cardTitle: c?.meta?.name ?? c?.name ?? '',
                cardImageURL: c?.meta?.thumbs?.graphic_288x152,
                carouselID: carouselId + `-${c?.slug}` ?? '',
            },
        }) as void;

    if (!tagName) {
        return null;
    }
    return { tagName, attributes, handleClick };
};
