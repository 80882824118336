'use client';

import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { useUnit } from 'effector-react';
import { LocalizedLink } from '@/components/Link';
import ButtonPrev from './button-prev.svg';
import ButtonNext from './button-next.svg';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { AnalyticsContext } from '@/components/Layout';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';
import { $arenaDataStore } from '@/app.model';
import { Card, overrideSlideAttributes, SplideOptions } from '@/features/carousel/model';
import { UiKitRenderStatic } from '@/components/UiKit/UiKitRender';
import { UIKIT_CONSTS } from '@/components/UiKit/UiKitConnector';
import { Badge } from '@/shared/api/arena-data';
import { $gameFeed } from '@/root/app/[locale]/games/[slug]/model';

// INITIAL CONFIG:
const INIT_OPTIONS: SplideOptions = {
    type: 'slide',
    pauseOnHover: true,
    pauseOnFocus: true,
    focus: 0,
    pagination: true, // Enable pagination
    omitEnd: true,
    autoWidth: true,
    lazyLoad: true,
    slideFocus: true,
    focusableNodes: '',
};

// TYPING:
type TSliderProps = {
    slidesList: any[];
    sliderOptions?: SplideOptions;
    analyticId?: string | null;
    infiniteLoop?: boolean | null;
    badgesList?: Badge[];
};

// COMPONENT
export const Slider = (props: TSliderProps) => {
    const arenaData = useUnit($arenaDataStore);
    const gameFeedData = useUnit($gameFeed);
    const slidesList: Card[] = props.slidesList || [];
    const infiniteLoop = props.infiniteLoop ?? false;
    const [options, _] = useState<SplideOptions>({ ...INIT_OPTIONS, ...props.sliderOptions });
    const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
    const splideRef = useRef<Splide>(null);
    const { isMobile } = useDeviceDetector();
    const isMobileDetected = isMobile();
    const { AITracks, AIReadyAsDep } = useContext(AnalyticsContext);
    const arrowsAlwaysVisible = arenaData?.layout?.themeLayout?.carouselArrowsVisible ?? false;
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
    const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);
    const [isArrowClicked, setIsArrowClicked] = useState(false);
    const getLocal = arenaData?.localeList?.list?.map((l: any) => l.code)?.[0] ?? 'en';

    const cardRefs = useRef<(HTMLAnchorElement | null)[]>([]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (['ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(event.key)) {
                setIsKeyboardNavigation(true);
            }
        };

        const handleMouseDown = () => {
            setIsKeyboardNavigation(false);
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    function sendAnalytics(eventName: string) {
        AITracks.genericTrack({
            eventName,
            isNonInteraction: false,
            eventAction: AnalyticsEventAction.CLICK,
            customDimensions: {
                carouselID: props.analyticId ?? null,
                carouselTitle: props.analyticId ?? null,
            },
        });
    }

    const getOptions = (isOverflow: boolean) => ({
        ...options,
        ...{
            arrows: isOverflow,
            pagination: isOverflow,
            drag: isOverflow,
            clones: isOverflow ? undefined : 0,
        },
    });

    const getOptionsMobile = () => ({
        drag: 'free',
        snap: true,
    });

    const onMounted = (splide: any) => {
        splide.go(0);
        const isOverflow = splide.root.classList.contains('is-overflow');
        const newOptions = isMobileDetected ? getOptionsMobile() : getOptions(isOverflow);
        splide.options = newOptions;
    };

    const handleTrackEvent = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;

        // To avoid double click one for li and another for the a tag, we just need the li since the click is on the Link ref
        if (target.tagName.toLowerCase() === 'a') {
            return;
        }

        const slideElement = target.closest('.splide__slide') as HTMLElement;

        if (!slideElement) return;

        event.stopPropagation();

        const index = slideElement.dataset.index; // Get the slide index from `data-index`
        if (index === undefined) return;

        const slideIndex = Number(index);

        if (event.type === 'click') {
            handleCardClick(slideIndex);
        } else if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter') {
            event.preventDefault();
            handleCardClick(slideIndex);
        }
    };

    const handleCardClick = (index: number) => {
        cardRefs.current[index]?.click(); // Programmatically trigger the Link's click
    };

    // Setup Splide events
    useEffect(() => {
        if (!splideRef?.current?.splideRef?.current) {
            return;
        }

        const splide = splideRef.current.splide;

        // Update carousel on overflow
        splide.on('overflow', (isOverflow: boolean) => {
            splide.go(0);
            const newOptions = getOptions(isOverflow);
            splide.options = newOptions;
        });

        // Update arrow visibility based on the current slide index
        splide.on('moved', (index: number) => {
            const isEnd = index === splide?.Components?.Controller?.getEnd?.();
            const isStart = index === 0;

            // Set arrow visibility based on the current position
            setIsLeftArrowVisible(!isStart);
            setIsRightArrowVisible(!isEnd);
        });
    }, [splideRef, isKeyboardNavigation, infiniteLoop]);

    const renderSlide = (card: Card, cardIndex: number) => {
        const overridenSlide = overrideSlideAttributes(
            card,
            cardIndex,
            arenaData,
            isMobile(),
            AITracks,
            props.badgesList ?? [],
            gameFeedData
        );
        if (!overridenSlide) {
            return null;
        }

        const { tagName, handleClick, html } = overridenSlide;

        const localizedLinkHref = (): string => {
            if (card?.link?.href) {
                return card.link.href;
            } else if (overridenSlide.attributes.game_alias) {
                return `/games/${overridenSlide.attributes.game_alias}`;
            } else {
                return `/games/${overridenSlide.attributes.game_slug}`;
            }
        };

        const labelTitle = card?.game?.name ? `Play ${card?.game?.name}` : 'Clickable card';

        return (
            <SplideSlide key={cardIndex} className="splide__slide" aria-label={labelTitle} data-index={cardIndex}>
                <LocalizedLink
                    tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                    key={card.link?.href}
                    ref={(el) => {
                        cardRefs.current[cardIndex] = el; // Store ref for each slide
                    }}
                    href={localizedLinkHref()}
                    className={UIKIT_CONSTS.nextjsLinkWrapperClassname}
                    target={card.link?.target ?? '_self'}
                    onClick={handleClick}
                    aria-label={labelTitle}
                >
                    <UiKitRenderStatic innerHTML={html} aria-label={card?.title || ''} tagName={tagName} />
                </LocalizedLink>
            </SplideSlide>
        );
    };

    const slides = useMemo(() => slidesList.map(renderSlide), [AIReadyAsDep]);

    // Handle left and right arrow clicks with focus update logic for keyboard users
    const handleArrowClick = async (isLeft: boolean) => {
        sendAnalytics(isLeft ? 'sliderLeft' : 'sliderRight');
        const splide = splideRef.current?.splide;
        setIsArrowClicked(true);

        if (splide) {
            if (isLeft) {
                splide.go('<');
            } else {
                splide.go('>');
            }
        }
    };

    // Handle keyboard navigation with Enter/Space key press
    const handleArrowKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, isLeft: boolean) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation(); // Stop propagation to prevent the event from firing multiple times
            handleArrowClick(isLeft);
        }
    };

    return (
        <Splide
            options={options}
            className="carousel__slider"
            hasTrack={false}
            role="navigation"
            ref={splideRef}
            onMounted={onMounted}
        >
            <div className="splide__arrows">
                <button
                    className={classNames('splide__arrow splide__arrow--prev', {
                        ['splide__arrow--visible']: isLeftArrowVisible || infiniteLoop,
                        ['__splide-arrow-clicked']: isArrowClicked || arrowsAlwaysVisible,
                    })}
                    aria-label="Go back to previous slides"
                    onClick={() => handleArrowClick(true)}
                    onKeyDown={(e) => handleArrowKeyDown(e, true)}
                    onMouseLeave={(ev) => ev.currentTarget.blur()}
                    tabIndex={ETabIndexesOrderingLevels.AUTO}
                >
                    <ButtonPrev />
                </button>
            </div>

            <SplideTrack
                tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                onClick={handleTrackEvent}
                onKeyDown={handleTrackEvent}
            >
                {slides}
            </SplideTrack>

            <div className="splide__arrows">
                <button
                    className={classNames('splide__arrow splide__arrow--next', {
                        ['splide__arrow--visible']: isRightArrowVisible || infiniteLoop,
                        ['__splide-arrow-clicked']: isArrowClicked,
                    })}
                    aria-label="Move to next slides"
                    onClick={() => handleArrowClick(false)}
                    onKeyDown={(e) => handleArrowKeyDown(e, false)}
                    onMouseLeave={(ev) => ev.currentTarget.blur()}
                    tabIndex={ETabIndexesOrderingLevels.AUTO}
                >
                    <ButtonNext />
                </button>
            </div>
        </Splide>
    );
};
