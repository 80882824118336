'use client';

import { Slider } from './Slider';
import { logger } from '@/shared/utils/logger';
import { TCarouselRecentlyPlayed } from './model';
import { LocalStorageService } from '@/shared/utils/local-storage';

const CarouselRecentlyPlayed = (props: TCarouselRecentlyPlayed) => {
    const title: string = props.title ?? '';
    const recentGame = LocalStorageService.getItem('recentlyPlayed');

    if (!recentGame) {
        logger.log('No recently played games found for this carousel', recentGame);
        return null;
    }

    return (
        <section data-carousel-type="recently-played-carousel" data-carousel-id={props.id} className='carousel'>
            <div className={'carousel_title'}>{title && <p className={'carousel_name'}>{title}</p>}</div>
            <Slider {...props} />
        </section>
    );
};

export default CarouselRecentlyPlayed;
