//@ts-ignore
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { WEB_COMPONENTS_NAMES } from '@/shared/app';

export type RawCarouselPromo = {
    __component: WEB_COMPONENTS_NAMES.CarouselPromo;
    carousels: TCarouselPromo[];
    id: number;
};

export type TButton = {
    url: string;
    label: string;
};

export type PromoSlide = {
    gradientOverlay: string;
    promos_library: Promo;
};

export type Promo = {
    id: number;
    title: string;
    button: TButton;
    image: {
        url: string;
    };
    text: string;
    background_color?: string;
    analyticId: string | null;
};

export type TCarouselPromo = {
    id: number;
    title: string;
    analyticId: string | null;
    slides: PromoSlide[];
    displayAd?: boolean;
    recentlyPlayed?: boolean;
    infiniteLoop?: boolean;
    index: number;
};

export type CardPromoAttributes = {
    onMobile: boolean;
    title: string;
    background_color?: string;
    background_image_desktop?: string;
    description?: string;
    link_label?: string;
    // unique from overrides
    override_color_description?: string;
    override_color_overlay?: string;
    override_color_category?: string;
    override_button_normal_border?: string;
    override_button_normal_background?: string;
    override_button_normal_background_end?: string;
    override_button_normal_color?: string;
    override_button_hover_border?: string;
    override_button_hover_background?: string;
    override_button_hover_background_end?: string;
    override_button_hover_color?: string;
    override_color_title?: string;
    override_hover_block_toggled?: 'true' | 'false';
    override_hover_block_color_bcg?: string;
    override_hover_block_color_text?: string;
};

export type TOverriddenSlideAttributes = {
    tagName: string;
    attributes: CardPromoAttributes;
    handleClick?: (AITracks?: any) => void;
};

export const overrideSlideAttributes = (
    c: PromoSlide,
    cardIndex: number,
    arenaData: any,
    isMobile: boolean,
    AITracks: any,
    carouselId?: string | null
): TOverriddenSlideAttributes | null => {
    let tagName;
    //For Analytics - card click event
    let handleClick;
    // attributes mapping
    const attributesInitial: CardPromoAttributes = {
        onMobile: isMobile,
        title: c?.promos_library?.title || '',
        description: c?.promos_library?.text || '',
        background_color: c?.gradientOverlay || 'transparent',
        background_image_desktop: c?.promos_library?.image?.url || '',
        link_label: c?.promos_library?.button?.label || '',
    };

    let attributes: CardPromoAttributes = { ...attributesInitial } as CardPromoAttributes;
    let attributesOverride: any = {};

    const mergedButtonAttributes = (initial: CardPromoAttributes, attributesOverride: any) => {
        const normalButton = attributesOverride.button?.find((b: { state: string }) => b.state === 'normal');
        const hoverButton = attributesOverride.button?.find((b: { state: string }) => b.state === 'hover');

        return {
            ...initial,
            override_color_text: attributesOverride.text || '',
            override_color_title: attributesOverride.title || '',
            override_color_overlay: attributesOverride.overlay || '',
            override_button_normal_border: normalButton?.border?.toString() || '',
            override_button_normal_background: normalButton?.background?.toString() || '',
            override_button_normal_background_end: normalButton?.background_end?.toString() || '',
            override_button_normal_color: normalButton?.color?.toString() || '',
            override_button_hover_border: hoverButton?.border?.toString() || '',
            override_button_hover_background: hoverButton?.background?.toString() || '',
            override_button_hover_background_end: hoverButton?.background_end?.toString() || '',
            override_button_hover_color: hoverButton?.color?.toString() || '',
        };
    };

    attributesOverride = arenaData?.layout?.styleOverride?.promoCard || {};

    attributes = mergedButtonAttributes(attributesInitial, attributesOverride);

    tagName = 'ark-ui-block-card-promo';

    handleClick = (): void =>
        AITracks?.genericTrack?.({
            eventName: 'gameCard',
            isNonInteraction: false,
            eventAction: AnalyticsEventAction.CLICK,
            customDimensions: {
                cardType: 'PromoCard',
                cardTitle: c?.promos_library?.title ?? '',
                cardImageURL: c?.promos_library?.image?.url ?? '',
                carouselID: carouselId ?? '',
            },
        }) as void;

    if (!tagName) {
        return null;
    }
    return { tagName, attributes, handleClick };
};
